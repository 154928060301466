<template>
  <div class="consignment-notes-registry">
    <div v-if="!pageLoad" class="page-loader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div
      v-if="currentConsignmentNotesRegistry.data"
      class="page-title d-flex py-2 justify-content-between"
    >
      <h1 class="h4 mb-0">
        Реестр товарных накладных №
        {{ currentConsignmentNotesRegistry.data.number }} от
        {{ currentConsignmentNotesRegistry.data.date | parseDate }}
      </h1>

      <div class="d-flex align-items-center">
        <BButton class="ml-3" variant="warning"> Экспорт </BButton>

        <SignButton :entry="currentConsignmentNotesRegistry.data" />

        <BButton
          v-if="
            isContractor &&
            currentConsignmentNotesRegistry.data.contr_agent_status ===
              'Черновик'
          "
          class="ml-3"
          variant="outline-primary"
          :to="`${$route.fullPath}/edit`"
          >Редактировать
        </BButton>

        <BButton
          v-if="
            isContractor &&
            currentConsignmentNotesRegistry.data.contr_agent_status ===
              'На рассмотрении'
          "
          class="ml-3"
          variant="danger"
          v-b-modal.reject
          >Отказать
        </BButton>

        <BButton
          v-if="
            isContractor &&
            currentConsignmentNotesRegistry.data.contr_agent_status ===
              'На рассмотрении'
          "
          class="ml-3"
          variant="primary"
          @click.prevent="approve"
          >Согласовать
        </BButton>

        <BButton
          v-if="
            currentConsignmentNotesRegistry.data.contr_agent_status ===
              'Черновик' &&
            currentConsignmentNotesRegistry.data.customer_status === 'Черновик'
          "
          class="ml-3"
          variant="primary"
          @click="approve"
          >Отправить на согласование
        </BButton>
      </div>
    </div>

    <b-tabs v-model="tabIndex" card v-if="currentConsignmentNotesRegistry.data">
      <b-tab title="Информация">
        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Номер РТН
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ currentConsignmentNotesRegistry.data.number }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Дата
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ currentConsignmentNotesRegistry.data.date | parseDate }}
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Статус согласования с заказчиком
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ currentConsignmentNotesRegistry.data.customer_status }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Статус согласования с контрагентом
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ currentConsignmentNotesRegistry.data.contr_agent_status }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Статус подписания со стороны поставщика
          </div>

          <div class="px-2 py-1 flex-grow-1">
            <div
              v-if="
                currentConsignmentNotesRegistry.data.provider_signature_dt &&
                currentConsignmentNotesRegistry.data
                  .provider_signature_file_name
              "
            >
              Подписан
              {{
                currentConsignmentNotesRegistry.data.provider_signature_dt
                  | parseDate
              }}
            </div>
            <div v-else>Не подписан</div>
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Статус подписания со стороны подрядчика
          </div>
          <div class="px-2 py-1 flex-grow-1">
            <div
              v-if="
                currentConsignmentNotesRegistry.data.contract_signature_dt &&
                currentConsignmentNotesRegistry.data
                  .contract_signature_file_name
              "
            >
              Подписан
              {{
                currentConsignmentNotesRegistry.data.contract_signature_dt
                  | parseDate
              }}
            </div>
            <div v-else>Не подписан</div>
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Филиал заказчик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{
              getOrganization(
                currentConsignmentNotesRegistry.data.organization_id
              ).name
            }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Подрядчик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{
              getContrAgent(
                currentConsignmentNotesRegistry.data.contractor_contr_agent_id
              ).name || currentConsignmentNotesRegistry.data.contractor.name
            }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Поставщик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{
              getContrAgent(
                currentConsignmentNotesRegistry.data.provider_contr_agent_id
              ).name || currentConsignmentNotesRegistry.data.provider.name
            }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Объект
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{
              currentConsignmentNotesRegistry.data.object && currentConsignmentNotesRegistry.data.object.name
            }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Подобъект
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{
              getSubObject(
                currentConsignmentNotesRegistry.data.customer_sub_object_id
              ).name
            }}
          </div>
        </div>

        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Ответственный исполнитель
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ currentConsignmentNotesRegistry.data.responsible_full_name }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Телефон
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{
                  currentConsignmentNotesRegistry.data.responsible_phone
                    | VMask("# (###) ###-##-##")
                }}
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Комментарий
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ currentConsignmentNotesRegistry.data.comment }}
          </div>
        </div>

        <div
            v-if="currentConsignmentNotesRegistry.data.file_url"
            class="d-flex table-bordered mb-1"
        >
            <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Приложение</div>
            <div class="px-2 py-1 flex-grow-1">
                <DownloadLink
                    :id="id"
                    from="consignment-registers"
                    :url="currentConsignmentNotesRegistry.data.file_url"
                />
            </div>
        </div>
      </b-tab>

      <b-tab title="Позиции">
        <TableFilter
          :columns="positionsFields"
          uniqueKey="table-2"
          @update-selected-columns="columns = $event"
          @search="filter = $event"
          @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
          @clear="clearFilterData"
        />

        <DefaultTable
          :data="currentConsignmentNotesRegistry.data.positions"
          :fields="currentConsignmentNotePositionsFieldsFiltered"
          :filter="filter"
          emptyText="Нет позиций"
          :withIterator="true"
          :loading="positionsLoading"
          :noLocalSorting="true"
          @sort-change="sorting"
          @pagination-change="paginationChange"
          @per-page-change="perPageChange"
        />
      </b-tab>
    </b-tabs>

    <b-modal id="reject" title="Отказать" centered>
      <p class="m-0">Вы уверены что хотите отказать?</p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button variant="danger" @click="reject">Да </b-button>
      </template>
    </b-modal>

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updatePositionsWithFilters(true)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";
import SignButton from "@/components/Sign/SignButton";
import DownloadLink from "@/components/UI/DownloadLink"

function initialFilterData() {
  return {
    consignment_number: "",
    consignment_date: ["", ""],
    nomenclature_name: "",
    mnemocode: "",
  };
}

export default {
  name: "ConsignmentNotesRegistry",
  components: {
    TableFilter,
    DefaultTable,
    FilterSidebar,
    SignButton,
    DownloadLink
  },
  computed: {
    id() {
      return this.$route.params.id;
    },

    ...mapState({
      currentConsignmentNotesRegistry: (state) =>
        state.consignmentNotesRegistries.currentConsignmentNotesRegistry,
    }),

    ...mapGetters([
      "isContractor",
      "getOrganization",
      "getContrAgent",
      "getObject",
      "getSubObject",
    ]),

    // computedPositionsCurrentConsignmentNotes() {
    //   return {
    //     ...this.currentConsignmentNotesRegistry,
    //     positions: this.currentConsignmentNotesRegistry.positions.data.map(
    //       (el) => ({
    //         ...el,
    //         number: this.currentConsignmentNotesRegistry.data.number,
    //       })
    //     ),
    //   };
    // },

    currentConsignmentNotePositionsFieldsFiltered() {
      let res = [];
      this.positionsFields.forEach((item) => {
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.positionsFields;
    },
    registryId() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    breadcrumbs: [
      { title: "Главная", link: "/" },
      { title: "Заказ ПО", link: "/" },
      {
        title: "Перечень реестров товарных накладных",
        link: "/consignment-notes-registries",
      },
    ],
    positionsFields: [
      {
        key: "consignment_number",
        sortable: true,
        label: "Номер ТН",
        full: "Номер товарной накладной",
        openDefault: true,
      },
      {
        key: "mnemocode",
        sortable: true,
        label: "Мнемокод",
        full: "Мнемокод",
        openDefault: true,
      },
      {
        key: "nomenclature_name",
        nesting: "nomenclature_name",
        sortable: true,
        label: "Номенклатура",
        full: "Номенклатура",
        openDefault: true,
      },
      {
        key: "count",
        sortable: true,
        label: "Количество",
        full: "Количество",
        openDefault: true,
      },
      {
        key: "price_without_vat",
        sortable: true,
        label: "Цена ₽ (без НДС)",
        full: "Цена ₽ (без НДС)",
        openDefault: true,
      },
      {
        key: "amount_without_vat",
        sortable: true,
        label: "Сумма ₽ (без НДС)",
        full: "Сумма ₽ (без НДС)",
        openDefault: true,
      },
      {
        key: "vat_rate",
        sortable: true,
        label: "Ставка НДС",
        full: "Ставка НДС",
        openDefault: true,
      },
      {
        key: "amount_with_vat",
        sortable: true,
        label: "Сумма ₽ (с НДС)",
        full: "Сумма ₽ (с НДС)",
        openDefault: true,
      },
      {
        key: "result_status",
        sortable: true,
        label: "Результат",
        full: "Результат",
        openDefault: true,
      },
    ],
    pageLoad: false,
    tabIndex: 0,
    columns: [],
    filter: "",
    filterData: initialFilterData(),
    positionsLoading: false,
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),

  watch: {
    currentConsignmentNotesRegistry: {
      handler(value) {
        if (value.data) {
          this.breadcrumbs = [
            { title: "Главная", link: "/" },
            { title: "Заказ ПО", link: "/" },
            {
              title: "Перечень реестров товарных накладных",
              link: "/consignment-notes-registries",
            },
            {
              title: `Реестр товарных накладных № ${
                value.data.number
              } от ${this.$options.filters.parseDate(value.data.date)}`,
            },
          ];
        }
      },
      deep: true,
    },
  },

  methods: {
    async getData() {
      this.pageLoad = false;
      await Promise.all([
        this.$store.dispatch("getConsignmentNotesRegistry", this.id),
        this.$store.dispatch("getConsigmentNoteRegistryPositions", {
          id: this.id,
        }),
      ]);
      this.pageLoad = true;
    },

    async reject() {
      await this.$store
        .dispatch("rejectConsigmentNoteRegistry", this.registryId)
        .then(() => {
          this.sendSuccess(
            "Реестр платежей товарных накладных успешно отклонен"
          );
          this.getData();
        })
        .catch((err) => {
          this.sendError(err);
        });
      this.$bvModal.hide("reject");
    },
    async approve() {
      await this.$store
        .dispatch("approveConsigmentNoteRegistry", this.registryId)
        .then(() => {
          this.sendSuccess(
            "Реестр платежей товарных накладных успешно согласован"
          );
          this.getData();
        })
        .catch((err) => {
          this.sendError(err);
        });
    },
    sendSuccess(successText) {
      this.$bvToast.toast(successText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "success",
      });
    },
    sendError(err) {
      const errText =
        err.response.status === 422
          ? "Проверьте правильность заполненных полей"
          : "Ошибка сервера. Попробуйте позднее.";
      this.$bvToast.toast(errText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "danger",
      });
    },
    async updatePositionsWithFilters(clearSort) {
      this.positionsLoading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getConsigmentNoteRegistryPositions", {
        id: this.id,
        filters: {
          ...this.sortingData,
          ...this.filterData,
        },
      });
      this.positionsLoading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.updatePositionsWithFilters(true);
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updatePositionsWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updatePositionsWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updatePositionsWithFilters();
    },
  },
  mounted() {
    this.positionsFields.forEach((item) => {
      if (item.openDefault) {
        this.columns.push(item.label);
      }
    });
    this.getData();
    this.$store.dispatch("checkNotification", {
      entity: "consignment_registers",
      id: this.registryId,
    });
  },
};
</script>

<style scoped>
</style>
