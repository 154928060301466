<template>
  <div>
    <b-button
      @click="$bvModal.show('modal-sign')"
      class="ml-3"
      variant="primary"
    >
      Подписать документ
    </b-button>

    <b-modal id="modal-sign" title="Подписать документ" hide-footer>
      <SignForm :entry="entry" />
    </b-modal>
  </div>
</template>

<script>
import SignForm from "./SignForm.vue";
export default {
  components: { SignForm },
  name: "SignButton",
  props: {
    entry: Object,
  },
};
</script>